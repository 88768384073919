import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
// import HarborViewLifestyle from "../components/Repeating/HarborViewLifestyle";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "gatsby-plugin-transition-link";
import { getServiceUrl } from "../lib/helpers";
import CallToAction from "../components/Repeating/CTA";

const ServicesPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="Senior Care Services | Point Loma Elder Care | MesaView"
        description="Get expert senior care services at MesaView Senior Assisted Living, a San Diego-area elderly care community in Point Loma. Ask us about our all-inclusive rates."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className=" mb-20 pt-10 md:mb-32 md:pt-16">
        <div className="container">
          <header className="mb-12 max-w-[688px] md:mb-14">
            <h1>La Mesa Senior Care Services</h1>
            <p>
              At MesaView Senior Assisted Living in the San Diego suburb of La
              Mesa, we provide care that helps our residents embrace life every
              day, age gracefully, and thrive. Ask us about our all-inclusive
              rates.
            </p>
          </header>
          <div className="grid gap-y-14  sm:grid-cols-2 sm:gap-x-5 md:grid-cols-3 md:gap-y-16 md:gap-x-10">
            {data.allSanityServices.nodes &&
              data.allSanityServices.nodes.map((node) => (
                <div key={node.id}>
                  <Link
                    to={getServiceUrl(node.slug.current)}
                    className="group block font-normal text-typography-body no-underline hover:text-typography-body"
                  >
                    {node.backgroundimage && node.backgroundimage.asset && (
                      <div className="mb-5 overflow-hidden rounded-[8px]">
                        <GatsbyImage
                          image={node.backgroundimage.asset.gatsbyImageData}
                          loading="lazy"
                          objectFit="cover"
                          width={250}
                          className="transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                        />
                      </div>
                    )}
                    <h2 className="heading-four">{node.title}</h2>
                    <p>{node.description}</p>
                    <a
                      className="border-b-2 border-tertiary-400 font-body text-[1rem] font-bold leading-[1.5rem] text-primary-500 no-underline"
                      href={getServiceUrl(node.slug.current)}
                    >
                      Learn More
                    </a>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </section>

      <CallToAction phonenumber={data.contactInfo.phonenumber} />
    </Layout>
  );
};

export const query = graphql`
  {
    allSanityServices(sort: { fields: title }) {
      totalCount
      nodes {
        title
        slug {
          _key
          _type
          current
        }
        description
        backgroundimage {
          asset {
            gatsbyImageData
          }
        }
      }
    }

    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }

    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Facebook.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter.jpg" }
    ) {
      publicURL
    }
    posts: allSanityBlogs(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

export default ServicesPage;
